<template>
	<div
		class="center-item loader-container"
		dir="ltr">
		<svg
			class="ip"
			viewBox="0 0 256 128"
			width="156px"
			height="28px"
			xmlns="http://www.w3.org/2000/svg">
			<defs>
				<linearGradient
					id="grad1"
					x1="0"
					y1="0"
					x2="1"
					y2="0">
					<stop
						offset="0%"
						stop-color="#854a04" />
					<stop
						offset="50%"
						stop-color="#f7941d" />
					<stop
						offset="100%"
						stop-color="#854a04" />
				</linearGradient>
				<linearGradient
					id="grad2"
					x1="1"
					y1="0"
					x2="0"
					y2="0">
					<stop
						offset="0%"
						stop-color="#854a04" />
					<stop
						offset="50%"
						stop-color="#f7941d" />
					<stop
						offset="100%"
						stop-color="#854a04" />
				</linearGradient>
			</defs>
			<g
				fill="none"
				stroke-linecap="round"
				stroke-width="16">
				<g stroke-dasharray="180 656">
					<path
						class="ip__worm1"
						stroke="url(#grad1)"
						stroke-dashoffset="0"
						d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
					<path
						class="ip__worm2"
						stroke="url(#grad2)"
						stroke-dashoffset="358"
						d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
				</g>
			</g>
		</svg>
	</div>
</template>

<script>
export default {
	name: 'loader',
};
</script>

<style lang="scss" scoped>
.loader-container {
	width: 100vw;
	height: 100vh;
}
/* all rights to https://codepen.io/jkantner/pen/mdKOpbe */
/************************ */
.ip {
	width: 8rem;
	height: 4rem;
}
.ip__worm1,
.ip__worm2 {
	animation: worm1 2s linear infinite;
}
.ip__worm2 {
	animation-name: worm2;
}

/* Animation */
@keyframes worm1 {
	from {
		stroke-dashoffset: 0;
	}
	50% {
		animation-timing-function: steps(1);
		stroke-dashoffset: -358;
	}
	50.01% {
		animation-timing-function: linear;
		stroke-dashoffset: 358;
	}
	to {
		stroke-dashoffset: 0;
	}
}
@keyframes worm2 {
	from {
		stroke-dashoffset: 358;
	}
	50% {
		stroke-dashoffset: 0;
	}
	to {
		stroke-dashoffset: -358;
	}
}
</style>

<template>
	<div
		id="app"
		:key="$i18n.locale">
		<loader v-if="loading && $route.name !== 'Home'"></loader>
		<main-nav
			v-if="!loading"
			@changeLang="changeLang" />
		<router-view
			id="main"
			v-show="!loading || $route.name === 'Home'"
			:loading="loading" />
		<main-footer v-if="!loading" />

		<div
			class="dialog center-item"
			@click.self="closeDialog"
			v-if="!loading && showDialog">
			<div class="dialog-card">
				<div
					class="service"
					v-if="serviceSlug">
					<div class="text-center">
						<div class="service__image image">
							<img
								:src="getServiceBySlug(serviceSlug).primaryImage?.url"
								:alt="getServiceBySlug(serviceSlug).title + ' image'" />
						</div>
						<div class="service__name">
							{{ getServiceBySlug(serviceSlug).title }}
						</div>
						<div class="service__summary">
							{{ getServiceBySlug(serviceSlug).summary }}
						</div>
						<hr class="mx-auto w-50" />
						<div
							class="service__description"
							v-html="getServiceBySlug(serviceSlug).content"></div>
					</div>
				</div>
				<div
					class="contact"
					v-else>
					<div class="text-center">
						<h3 class="main-text fw-light h4 mb-0 text-capitalize">contact us</h3>
						<h2>Get Touch with Us</h2>
					</div>
					<contact-us-form
						:dialog="true"
						:messageType="messageType"></contact-us-form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import MainFooter from './components/MainFooter.vue';
import MainNav from './components/MainNav.vue';
import ContactUsForm from './components/ContactUsForm.vue';
import Loader from './components/Loader.vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { mapGetters } from 'vuex';
import { dom } from '@fortawesome/fontawesome-svg-core';
import { changeI18nLanguage } from '@/helpers/Lang/index';

export default {
	data() {
		return {
			loading: true,

			showDialog: false,
			serviceSlug: null,
			messageType: null,
		};
	},

	components: { MainFooter, MainNav, ContactUsForm, Loader },

	computed: {
		...mapGetters({
			getServiceBySlug: 'services/getBySlug',
		}),
	},

	methods: {
		closeDialog() {
			document.querySelector('body').style.overflow = 'auto';
			this.showDialog = false;
		},
		changeLang(l) {
			if (l === this.$i18n.locale) return;
			changeI18nLanguage(l);
			this.fetch();
		},
		fetch() {
			window.axios.defaults.headers.common['accept-language'] = this.$i18n.locale;
			this.loading = true;
			document.querySelector('body').style.overflow = 'hidden';
			Promise.all([
				this.$store.dispatch('services/fetchAll'),
				this.$store.dispatch('aboutus/fetchAll'),
				this.$store.dispatch('values/fetchAll'),
				this.$store.dispatch('contacts/fetchAll'),
			]).then(() => {
				document.querySelector('body').style.overflow = 'auto';
				this.loading = false;
				dom.watch();
			});
		},
	},

	created() {
		console.log('Powered By Midad ❤');
		this.fetch();

		this.$eventBus.$on('show-dialog', (payload) => {
			this.showDialog = true;
			this.serviceSlug = null;
			document.querySelector('body').style.overflow = 'hidden';
			if (payload.slug) this.serviceSlug = payload.slug;
			if (payload.type) this.messageType = payload.type;
		});
		AOS.init({
			duration: 500,
			offset: 200,
			once: true,
		});
	},
};
</script>
<style lang="scss">
@import '@/style/style.scss';
@import '@/style/helpers.scss';
@import '@/style/components.scss';

.page {
	margin-top: 130px;
}
.sub-title {
	color: map-get($map: $colors, $key: 'main');
	font-size: 1.3rem;
	text-transform: capitalize;
	@include twoValues(font-weight, lighter, 500);
	margin-bottom: 0.15rem;
	word-spacing: 4px;
}
.title {
	font-size: 2rem;
	font-weight: bold;
}
.button {
	color: white;
	background-color: map-get($map: $colors, $key: 'sec');
	padding: 0.9em 1.2em;
	border-radius: 10px;
	border-top-right-radius: 0;
	width: max-content;
	text-transform: uppercase;
	font-size: 0.8rem;
	letter-spacing: 1px;
	border: 1px solid transparent;
	&:not(:disabled):hover {
		opacity: 0.9;
	}
}
.button-liner {
	color: map-get($map: $colors, $key: 'main');
	padding: 0.9em 1.2em;
	border-radius: 10px;
	border-bottom-left-radius: 0;
	border: 1px solid map-get($map: $colors, $key: 'main');
	width: max-content;
	text-transform: uppercase;
	font-size: 0.8rem;
	letter-spacing: 1px;
	&:not(:disabled):hover {
		background-color: #f7951d1b;
	}
}
button {
	&:focus-visible {
		outline: 2px solid #333;
	}
	&:disabled {
		opacity: 0.5;
	}
}
.radius-tr {
	@include twoProperties(border-top-right-radius, border-top-left-radius, 0);
}
.radius-tl {
	@include twoProperties(border-top-left-radius, border-top-right-radius, 0);
}
.radius-bl {
	@include twoProperties(border-bottom-left-radius, border-bottom-right-radius, 0);
}

@media (min-width: 1400px) {
	.container {
		max-width: 1140px !important;
	}
}

.dialog {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: #0003;
	z-index: 100;
}

.dialog-card {
	margin-top: 5vh;
	box-shadow: map-get($map: $shadows, $key: 3);
	background-color: white;
	padding: 2rem;
	border-radius: 15px;
	width: 500px;
	max-height: 90vh;
	overflow: auto;
	@media (max-width: 576px) {
		width: 90vw;
		padding: 1rem;
	}
}

.service {
	&__image {
		width: 3rem;
		height: 3rem;
		margin: auto;
		margin-bottom: 0.5rem;
	}
	&__name {
		font-size: 1.1rem;
		font-weight: bold;
		margin-bottom: 0.5rem;
	}
	&__summary {
		font-size: 1rem;
		margin-bottom: 0.5rem;
		line-height: 1.7em;
	}
	&__description {
		max-height: 200px;
		overflow: auto;
	}
	&__description * {
		font-size: 0.9rem;
	}
}
</style>

import * as types from "../mutations"

export default {
    namespaced: true,

    status: {
        services: []
    },

    actions: {
        //fetch all services
        fetchAll({ commit }) {
            return axios.get('/services').then(res => {
                commit(types.SERVICES_STORE, res.data)
                return res.data
            })
        },

        //return the services. and if it's empty fetch services
        fetchAllOrGet({ commit, state }) {
            return state.services || axios.get('/services').then(res => {
                commit(types.SERVICES_STORE, res.data)
                return res.data
            })
        },

        //fetch one services by it's slug
        fetchBySlug({ commit }, { slug }) {
            return axios.get(`services/${slug}`).then(res => res.data)
        },

        //get one services by it's slug, and if it's not found fetch it
        fetchByStateOrSlug({ commit, state }, { slug }) {
            let services = null;
            if (state.services) {
                services = state.services.find(e => e.slug === slug)
            }
            return services || axios.get(`/services/${slug}`).then(res => res.data)
        }
    },

    mutations: {
        //fill the state with services
        [types.SERVICES_STORE](state, services) {
            state.services = services
        }
    },

    getters: {
        //return one services by it's slug
        getBySlug: state => slug => state.services.find(e => e.slug === slug)
    }
}
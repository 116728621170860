<template>
	<div
		class="all-nav"
		v-if="showAll || $route.name !== 'Home'">
		<!-- skip to main content -->
		<a
			href="#main"
			class="skip-to">
			skip to main content
		</a>
		<div class="top-nav grey-bg d-none d-md-block">
			<div class="container pb-1 pt-2">
				<div class="d-flex flex-wrap justify-content-between">
					<div class="d-flex flex-wrap">
						<div
							class="location"
							v-if="contacts.address">
							{{ contacts.address }}
						</div>
						<a
							:href="`mailto://${contacts.email}`"
							target="_blank"
							class="email mx-0 mx-sm-3"
							v-if="contacts.email">
							{{ contacts.email }}
						</a>
					</div>
					<div
						class="social social--fill main-text"
						v-if="contacts">
						<a
							:href="contacts.twitter"
							target="_blank"
							class="twitter"
							title="twitter"
							v-if="contacts.twitter"></a>
						<a
							:href="contacts.instagram"
							target="_blank"
							class="instagram"
							title="instagram"
							v-if="contacts.instagram"></a>
						<a
							:href="contacts.linkedIn"
							target="_blank"
							class="linkedIn"
							title="linkedIn"
							v-if="contacts.linkedIn"></a>
						<a
							:href="contacts.facebook"
							target="_blank"
							class="facebook"
							title="facebook"
							v-if="contacts.facebook"></a>
					</div>
				</div>
			</div>
		</div>
		<div class="sec-bg">
			<div class="container py-4">
				<!-- go to top -->
				<div
					role="button"
					class="go-to-top"
					:class="{ show: isFarFromTop }"
					@click="goToTop"
					@keydown.enter="openDropdown = !openDropdown"
					tabindex="0">
					<i class="fas fa-angle-up"></i>
				</div>

				<!-- main nav -->
				<div class="main-nav align-items-center">
					<!-- brand -->
					<div class="brand">
						<router-link :to="{ name: 'Home' }">
							<div class="image">
								<img
									src="@/assets/logo.png"
									alt="brand logo" />
							</div>
						</router-link>
					</div>

					<!-- nav links -->
					<nav
						class="links-menu"
						:class="{ show: showNav }">
						<ul>
							<!-- link -->
							<li>
								<router-link
									:to="{ name: 'Home' }"
									target="link">
									{{ $t('links.home') }}
								</router-link>
							</li>
							<!-- link -->
							<li>
								<router-link
									:to="{ name: 'AllServices' }"
									target="link">
									{{ $t('links.services') }}
								</router-link>
							</li>
							<!-- link -->
							<li>
								<router-link
									:to="{ name: 'AboutUs' }"
									target="link">
									{{ $t('links.about') }}
								</router-link>
							</li>
							<!-- link -->
							<li>
								<router-link
									:to="{ name: 'ContactUs' }"
									target="link">
									{{ $t('links.contact') }}
								</router-link>
							</li>
							<!-- link -->
							<li
								class="fav-link"
								v-if="contacts && contacts.mobile">
								<a
									:href="`tel://${contacts.mobile}`"
									target="link">
									<div class="d-flex align-items-center">
										<div class="mx-1">
											<i class="fas fa-phone main-text"></i>
										</div>
										<div>
											<div>{{ $t('links.call') }}</div>
											<div
												class="main-text"
												dir="ltr">
												{{ contacts.mobile }}
											</div>
										</div>
									</div>
								</a>
							</li>
							<!-- link with dropdown -->
							<li
								ref="dropdown"
								tabindex="0"
								class="with-dropdown"
								role="button"
								:class="{ down: showDropdown }"
								@click="showDropdown = !showDropdown"
								@keydown.enter="showDropdown = !showDropdown">
								<span class="lang-icon"></span>
								<div
									class="dropdown-container"
									v-if="showDropdown">
									<ul>
										<li @click="$emit('changeLang', 'en')">En</li>
										<li @click="$emit('changeLang', 'ar')">ع</li>
									</ul>
								</div>
							</li>
						</ul>
					</nav>

					<!-- nav toggle (for mobile) -->
					<div
						class="hamburger touch"
						ref="navbar">
						<button
							:title="!showNav ? 'open navbar' : 'close navbar'"
							:aria-label="!showNav ? 'open navbar' : 'close navbar'"
							:aria-expanded="showNav ? true : false"
							class="menu-trigger white-text"
							:class="{ active: showNav }"
							@click="showNav = !showNav">
							<span></span>
							<span></span>
							<span></span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'MainNav',
	data() {
		return {
			showAll: false,

			showNav: false,
			showDropdown: false,

			isFarFromTop: false,
		};
	},

	methods: {
		goToTop() {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		},
	},

	computed: {
		...mapState({
			contacts: (state) => state.contacts.contacts,
		}),
	},

	mounted() {
		window.addEventListener('click', (e) => {
			if (this.$refs.dropdown?.contains(e.target)) {
				return;
			}
			if (this.$refs.navbar?.contains(e.target)) {
				this.showDropdown = false;
				return;
			}
			this.showNav = false;
			this.showDropdown = false;
		});

		window.addEventListener('scroll', (e) => {
			this.isFarFromTop = window.innerHeight / 2 < window.pageYOffset;
		});

		const showNav = (e) => {
			this.showAll = 10 < window.pageYOffset;
			if (this.showAll) {
				window.removeEventListener('scroll', showNav);
			}
		};
		window.addEventListener('scroll', showNav);
	},
};
</script>

<style lang="scss">
@import '@/style/style.scss';
.top-nav * {
	font-size: 0.8rem;
}
.top-nav svg {
	color: map-get($map: $colors, $key: 'main');
}
.all-nav {
	z-index: 3;
	position: fixed;
	left: 0;
	right: 0;
	animation: nav-enter 1s ease-out forwards;
}
@keyframes nav-enter {
	from {
		top: -200px;
	}
	to {
		top: 0px;
	}
}
</style>

<style scoped lang="scss">
@import '@/style/mixins.scss';
@import '@/style/style.scss';

$breakpoint: 768px; // nav turn into mobile
$order: (
	'brand': 1,
	'links-menu': 2,
	'hamburger': 3,
	'actions-menu': 4,
);
$mobile-order: (
	'brand': 2,
	'links-menu': 4,
	'hamburger': 1,
	'actions-menu': 3,
);

@mixin brand {
	max-width: max-content;
	height: 2rem;
}

@mixin links-item {
	font-size: 1.2rem;
	color: white;
	text-transform: capitalize;
	font-weight: 200;
}
@mixin links-item-hover {
	color: map-get($map: $colors, $key: 'main');
}
$links-item-margin: (
	'big': 3rem,
	992px: 2rem,
	765px: 0,
);
$absolute-nav: true;
$mobile-links-end: translate(0, 0); // for absolute nav
$mobile-links-padding: 1rem; // for static nav
@mixin mobile-links {
	text-align: center;
	transition: 0.5s ease-in-out;
	flex-direction: column;

	overflow-y: auto;
	@if ($absolute-nav) {
		background-color: #fffd;
		padding: 1rem;
		border-radius: 15px;

		transform: translate(0, -180%);
		top: calc(100% + 1rem);
		right: 15px;
		left: 15px;
		z-index: 2;
		box-shadow: map-get($map: $shadows, $key: 2);
		max-height: calc(100vh + 3rem);
	} @else {
		max-height: 0vh;
	}
}
@mixin mobile-links-item {
	margin-bottom: 1rem;
	color: black;
}

@mixin actions-item {
	font-size: 1.2rem;
}
@mixin actions-item-hover {
	color: map-get($map: $colors, $key: 'main');
}
$actions-item-margin: (
	'big': 0.5rem,
	765px: 0.3rem,
	576px: 0rem,
);

$hamburger-size: 1.5rem;

@mixin dropdown {
	background-color: #fffd;
	padding: 1rem;
	border-radius: 15px;
	color: black;
	text-align: center;
	@include right(-1rem);
}
@mixin dropdown-item {
	margin-bottom: 0.3rem;
}
@mixin dropdown-item-hover {
	color: map-get($map: $colors, $key: 'main');
}

.fav-link {
	text-align: start;
	div {
		font-size: 0.8em;
	}
	svg {
		transition: 0.3s;
		font-size: 1.8em;
	}
	&:hover {
		svg {
			transform: scale(1.1);
		}
	}
}
@keyframes dropdownAnimation {
	from {
		max-height: 0;
		transform: translateY(-8px);
	}
	to {
		max-height: 500px;
		transform: translateY(0);
	}
}

.go-to-top {
	position: fixed;
	bottom: 2rem;
	@include right(2rem);

	width: 3rem;
	height: 3rem;
	display: none;
	&.show {
		display: flex;
	}
	align-items: center;
	justify-content: center;
	color: white;
	background-color: map-get($map: $colors, $key: 'main');
	box-shadow: map-get($map: $shadows, $key: 0);
	border-radius: 50%;
}

/**************/
.main-nav {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	flex-wrap: wrap;

	position: relative;
}

.brand {
	@include brand;
	order: map-get($map: $order, $key: 'brand');
	.image {
		width: 100%;
		height: 100%;
	}
}

.hamburger {
	font-size: $hamburger-size;
	order: map-get($map: $order, $key: 'hamburger');
	@media (min-width: $breakpoint) {
		display: none;
	}
}

.links-menu {
	order: map-get($map: $order, $key: 'links-menu');

	> ul {
		display: flex;
		align-items: center;
		> li {
			@include links-item;
			&:not(.fav-link):hover {
				@include links-item-hover;
			}
		}
		> li:not(:last-child) {
			@each $breakpoint, $value in $links-item-margin {
				@if ($breakpoint == 'big') {
					@include marginEnd($value);
				} @else {
					@media (max-width: $breakpoint) {
						@include marginEnd($value);
					}
				}
			}
		}
	}

	@media (max-width: $breakpoint) {
		@if ($absolute-nav) {
			position: absolute;
			&.show {
				transform: $mobile-links-end;
			}
		} @else {
			width: 100%;
			padding: 0;
			&.show {
				max-height: 90vh;
				padding: $mobile-links-padding;
			}
		}
		@include mobile-links;
		> ul {
			flex-direction: column;
			> li {
				@include mobile-links-item;
			}
		}
	}
}

.actions-menu {
	order: map-get($map: $order, $key: 'actions-menu');

	> ul {
		display: flex;
		align-items: center;
		> li {
			@include actions-item;
			&:hover {
				@include actions-item-hover;
			}
		}
		> li:not(:last-child) {
			@each $breakpoint, $value in $actions-item-margin {
				@if ($breakpoint == 'big') {
					@include marginEnd($value);
				} @else {
					@media (max-width: $breakpoint) {
						@include marginEnd($value);
					}
				}
			}
		}
	}
}

.with-dropdown > .dropdown-container {
	animation: dropdownAnimation 0.3s forwards;
	overflow: hidden;

	@media (min-width: $breakpoint) {
		position: absolute;
		top: 100%;
		z-index: 2;
		box-shadow: map-get($map: $shadows, $key: 1);
	}
	@media (max-width: $breakpoint) {
	}
	@include dropdown;
	li {
		@include dropdown-item;
		&:hover {
			@include dropdown-item-hover;
			cursor: pointer;
		}
	}
}

@each $part, $order in $mobile-order {
	@media (max-width: $breakpoint) {
		.#{$part} {
			order: $order;
		}
	}
}

.skip-to {
	position: absolute;
	@include left(0);
	z-index: -999;
	padding: 1em;
	background-color: black;
	color: white;
	font-size: 14px;
	top: -5em;
}
.skip-to:focus-visible {
	top: 0;
	z-index: 999;
}
/**************************************************** */
// all rights to https://codepen.io/nxworld/pen/RPLLbm
.menu-trigger {
	position: relative;
	width: 1em;
	height: 1em;
	appearance: none;
	transition: all 0.6s;
	vertical-align: middle;
	span {
		transition: all 0.6s;
		position: absolute;
		left: 0;
		width: 100%;
		height: 0.14em;
		color: inherit;
		background-color: currentColor;
		border-radius: 0.14em;
		&:nth-of-type(1) {
			top: 0;
		}
		&:nth-of-type(2) {
			top: 0.47em;
		}
		&:nth-of-type(3) {
			bottom: 0;
		}
	}
	&.active {
		transform: rotate(270deg);
		span {
			&:nth-of-type(1) {
				transform: translateY(0.5em) rotate(-45deg);
			}
			&:nth-of-type(2) {
				transform: translateY(0) rotate(45deg);
			}
			&:nth-of-type(3) {
				transition: 0.2s;
				opacity: 0;
			}
		}
	}
}
</style>

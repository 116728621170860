<template>
	<main
		class="page py-4"
		id="main">
		<div class="container">
			<h3 class="sub-title">{{ $t('about.about.title-1') }}</h3>
			<h2 class="title">{{ $t('about.about.title-2') }}</h2>
			<div
				class="mb-5 text-justify"
				v-html="about('about')"></div>
		</div>

		<section class="quality">
			<div class="container">
				<h2 class="main-text mb-4">
					{{ $t('about.quality.title') }}
				</h2>
				<div
					class="white-text text-justify"
					v-html="about('qualityPolicy')"></div>
			</div>
		</section>
		<div class="container pt-5">
			<h3 class="sub-title">{{ $t('about.why.title-1') }}</h3>
			<h2 class="title">{{ $t('about.why.title-2') }}</h2>
			<div
				class="mb-5 text-justify"
				v-html="about('policy')"></div>
			<h2 class="title">{{ $t('about.why.title-3') }}</h2>
			<div
				class="mb-5 text-justify"
				v-html="about('mission')"></div>
			<h2 class="title">{{ $t('about.why.title-4') }}</h2>
			<div
				class="mb-5 text-justify"
				v-html="about('vision')"></div>
		</div>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'AboutUs',

	computed: {
		...mapGetters({
			about: 'aboutus/getValue',
		}),
	},

	metaInfo: {
		title: 'About Us',
		titleTemplate: '%s | Esnad united',
	},
};
</script>

<style lang="scss" scoped>
@import '@/style/style';

.quality {
	background-image: linear-gradient(#0009, #0009), url('@/assets/2.jpg');
	background-attachment: fixed;
	background-size: cover;
	padding: 4rem 0;
}
</style>

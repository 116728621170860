<template>
	<main
		id="main"
		class="page py-5">
		<div class="container">
			<h3 class="sub-title">{{ $t('contact.title-1') }}</h3>
			<h2 class="title contacts-title mb-3">
				{{ $t('contact.title-2') }}
			</h2>
			<div class="row gy-5 justify-content-between">
				<div class="col-12 col-md-6">
					<contact-us-form :dialog="true"></contact-us-form>
				</div>
				<div class="col-12 col-md-5">
					<template v-if="contacts">
						<div class="sfs-4 fw-bold">
							{{ $t('contact.customer') }}
						</div>
						<a
							:href="`tel://${contacts.mobile}`"
							target="_blank"
							class="d-block sfs-4 mb-4">
							<span dir="ltr">
								{{ contacts.mobile }}
							</span>
						</a>
						<div class="sfs-4 fw-bold">{{ $t('contact.email') }}</div>
						<a
							:href="`mailto://${contacts.twitter}`"
							target="_blank"
							class="d-block sfs-4 mb-4">
							{{ contacts.email }}
						</a>
						<div class="sfs-4 fw-bold">{{ $t('contact.address') }}</div>
						<div class="sfs-4 mb-4">
							{{ contacts.address }}
							<br />
							{{ branch.openHours }}
						</div>
					</template>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import ContactUsForm from '@/components/ContactUsForm.vue';
import { mapState } from 'vuex';

export default {
	name: 'contactUs',

	components: { ContactUsForm },

	computed: {
		...mapState({
			contacts: (state) => state.contacts.contacts,
			branch: (state) => state.contacts.branch,
		}),
	},

	metaInfo: {
		title: 'Contact Us',
		titleTemplate: '%s | Esnad united',
	},
};
</script>

export default {
    namespaced: true,
    actions: {
        feedback({ commit }, { name, email, phone, type, body }) {
            return axios
                .post('/feedback', { name, email, phone, type, body })
                .then((res) => {
                    console.log(res);
                    return res;
                })
                .catch((err) => {
                    throw err;
                });
        },
    },
};

<template>
	<main>
		<header class="center-item">
			<div
				class="header-content text-center"
				:class="{ final: !loading }">
				<h2>{{ $t('header.welcome') }}</h2>
				<h1>
					{{ $t('header.title-1') }} <br />
					<span>{{ $t('header.title-2') }}</span>
				</h1>
				<div
					class="d-flex justify-content-around header-buttons"
					v-if="!loading">
					<button
						class="button-liner px-5 fw-bold text-capitalize"
						@click="
							$eventBus.$emit('show-dialog', {
								type: $t('contact.form.types.consultation'),
							})
						">
						{{ $t('header.consultation') }}
					</button>
					<!-- <button
						class="button-liner"
						@click="
							$eventBus.$emit('show-dialog', {
								type: $t('contact.form.types.appointment'),
							})
						">
						{{ $t('header.appointment') }}
					</button> -->
				</div>
				<div class="header-loading">
					<template v-if="loading">
						{{ $t('loading') }}
					</template>
					<scroll-to-bottom v-else-if="showArrow"></scroll-to-bottom>
				</div>
			</div>
		</header>

		<template v-if="!loading">
			<section class="values">
				<div class="container">
					<div class="row gy-3">
						<div
							v-for="(value, i) in values.slice(0, 3)"
							:key="i"
							class="col-12 col-md-4"
							:data-aos="i === 0 ? 'zoom-in-left' : i === 1 ? 'zoom-in' : 'zoom-in-right'">
							<div
								class="values-card text-center px-3 py-4 sec-bg white-text radius-lg"
								:class="i === 0 ? 'radius-tr' : i === 1 ? '' : 'radius-bl'">
								<div
									class="image mx-auto mb-2"
									style="height: 3rem">
									<img
										:src="value.primaryImage?.url"
										:alt="`${value.title} image`" />
								</div>
								<strong class="mb-1 d-block">{{ value.title }}</strong>
								<div
									class="sfs-4"
									v-html="value.content"></div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="about py-5">
				<div class="container">
					<div class="row gy-3">
						<div class="col-12 col-md-8">
							<h3 class="sub-title">
								{{ $t('home.about.title-1') }}
							</h3>
							<h2 class="title">
								{{ $t('home.about.title-2') }}
							</h2>
							<div
								class="mb-5 text-justify"
								v-html="about('about')"></div>
							<router-link :to="{ name: 'AboutUs' }">
								<button class="button">
									{{ $t('home.about.button') }}
								</button>
							</router-link>
						</div>
						<div class="col-12 col-md-4">
							<div class="image image--cover radius-lg radius-tr border-white">
								<img
									src="@/assets/3.png"
									alt="" />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="statics">
				<div class="container">
					<div class="row gy-3">
						<div class="col-6 col-md-3">
							<div class="text-center px-3 white-text">
								<div class="h1">25+</div>
								<div>
									{{ $t('home.statics.expert') }}
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3">
							<div class="text-center px-3 white-text">
								<div class="h1">86+</div>
								<div>
									{{ $t('home.statics.consultation') }}
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3">
							<div class="text-center px-3 white-text">
								<div class="h1">121+</div>
								<div>
									{{ $t('home.statics.experience') }}
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3">
							<div class="text-center px-3 white-text">
								<div class="h1">3760+</div>
								<div>
									{{ $t('home.statics.parts') }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="services">
				<div class="container">
					<div
						class="section-card text-center"
						data-aos="zoom-in">
						<h3 class="sub-title">
							{{ $t('home.services.title-1') }}
						</h3>
						<h2 class="title mb-5">
							{{ $t('home.services.title-2') }}
						</h2>

						<div class="row gy-4 justify-content-center">
							<div
								data-aos="zoom-in"
								class="col-6 col-md-4"
								v-for="(service, i) in services"
								:key="i">
								<service-card
									:data="service"
									:class="{
										'radius-tr': !(i % 3),
										'radius-bl': !((i % 3) - 2),
									}"></service-card>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="why-choose pt-0 pt-md-3 pb-5">
				<div class="container">
					<div class="row gx-0 gx-md-5 gy-3">
						<div class="col-12 col-md-5">
							<div class="image image--cover radius-lg radius-tl border-white">
								<img
									src="@/assets/4.jpg"
									alt="" />
							</div>
						</div>
						<div class="col-12 col-md-7">
							<h3 class="sub-title">
								{{ $t('home.why.title-1') }}
							</h3>
							<h2 class="title text-justify">
								{{ $t('home.why.title-2') }}
							</h2>
							<div
								class="sfs-5 text-justify why-content mb-4"
								v-html="about('policy')"></div>
							<div class="d-flex">
								<div style="flex: 1">
									<strong>{{ $t('home.why.title-3') }}</strong>
									<div
										class="sfs-5 text-justify"
										v-html="about('mission')"></div>
								</div>
								<div
									class="mx-4"
									style="flex: 1">
									<strong>{{ $t('home.why.title-4') }}</strong>
									<div
										class="sfs-5 text-justify"
										v-html="about('vision')"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="quality">
				<div class="container text-center">
					<h2 class="main-text mb-4">
						{{ $t('home.quality.title') }}
					</h2>
					<div
						class="white-text quality-content mb-4"
						v-html="about('qualityPolicy')"></div>
					<router-link :to="{ name: 'AboutUs' }">
						<button class="button">
							{{ $t('home.about.button') }}
						</button>
					</router-link>
				</div>
			</section>

			<section class="contacts">
				<div class="container">
					<div
						class="section-card"
						data-aos="zoom-in">
						<div class="row gy-3 gx-0 gx-md-5 flex-row-reverse">
							<div class="col-12 col-md-6">
								<h3 class="sub-title">
									{{ $t('contact.title-1') }}
								</h3>
								<h2 class="title contacts-title">
									{{ $t('contact.title-2') }}
								</h2>
								<div class="sfs-4 fw-bold mb-1">
									{{ $t('contact.customer') }}
								</div>
								<template v-if="contacts">
									<a
										:href="`tel://${contacts.mobile}`"
										target="_blank"
										class="d-block sfs-4 mb-4">
										<span dir="ltr">
											{{ contacts.mobile }}
										</span>
									</a>
									<div class="sfs-4 fw-bold mb-1">
										{{ $t('contact.email') }}
									</div>
									<a
										:href="`mailto://${contacts.twitter}`"
										target="_blank"
										class="d-block sfs-4 mb-4">
										{{ contacts.email }}
									</a>
									<div class="sfs-4 fw-bold mb-1">
										{{ $t('contact.address') }}
									</div>
									<div class="sfs-4 mb-4">
										{{ contacts.address }}
										<br />
										{{ branch.openHours }}
									</div>
								</template>
								<!-- <div class="sfs-4 fw-bold">
									customer service
								</div>
								<div class="sfs-4 mb-4">+963987654321</div> -->
							</div>
							<div class="col-12 col-md-6">
								<contact-us-form></contact-us-form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
	</main>
</template>

<script>
import ServiceCard from '@/components/ServiceCard.vue';
import ScrollToBottom from '@/components/ScrollToBottom.vue';
import ContactUsForm from '@/components/ContactUsForm.vue';
import { mapState, mapGetters } from 'vuex';

export default {
	name: 'HomeView',

	data() {
		return {
			showArrow: true,
			arrowEvent: null,
		};
	},

	props: ['loading'],

	components: {
		ServiceCard,
		ScrollToBottom,
		ContactUsForm,
	},

	computed: {
		...mapState({
			values: (state) => state.values.values,
			services: (state) => state.services.services,
			contacts: (state) => state.contacts.contacts,
			branch: (state) => state.contacts.branch,
		}),

		...mapGetters({
			about: 'aboutus/getValue',
		}),
	},

	mounted() {
		const arrowEvent = (e) => {
			this.showArrow = 100 < window.pageYOffset;
			if (!this.showArrow) {
				window.removeEventListener('scroll', arrowEvent);
			}
		};
		window.addEventListener('scroll', arrowEvent);
	},

	metaInfo: {
		titleTemplate: 'Esnad united',
	},
};
</script>

<style scoped lang="scss">
@import '@/style/style.scss';
@import '@/style/mixins.scss';

.about {
	.image {
		height: 400px;
		@media (max-width: 575px) {
			height: 300px;
		}
	}
}

.why-choose {
	.image {
		height: 400px;
		@media (max-width: 575px) {
			height: 300px;
		}
		@include justArabic {
			transform: scaleX(-1);
		}
	}
}

header {
	height: 100vh;
	background-image: linear-gradient(#0007, #0007), url('@/assets/0.jpg'),
		linear-gradient(map-get($colors, 'sec'), map-get($colors, 'main'));
	background-size: cover;
	background-position: center;
	h2 {
		transition: 1s;
		font-size: 5rem;
		font-weight: bold;
		color: white;
		@media (max-width: 576px) {
			font-size: 4rem;
		}
	}
	h1 {
		font-size: 3rem;
		color: map-get($map: $colors, $key: 'main');
		span {
			color: white;
		}
		transition: 1s;
		max-height: 0;
		overflow: hidden;
		margin-bottom: 1rem;
		@media (max-width: 576px) {
			font-size: 2.5rem;
		}
	}
	.header-buttons {
		transition: 1s;
		max-height: 0;
		overflow: hidden;
		* {
			font-size: 1rem;
			@media (max-width: 576px) {
				font-size: 0.8rem;
			}
		}
	}
	.final {
		h2 {
			animation: header-h2 1s forwards;
			@keyframes header-h2 {
				from {
					font-size: 5rem;
				}
				to {
					font-size: 1.3rem;
				}
			}
		}
		h1 {
			animation: header-h1 0.8s forwards 0.7s;
			@keyframes header-h1 {
				from {
					max-height: 0;
					opacity: 0;
				}
				to {
					max-height: 12rem;
					opacity: 1;
				}
			}
		}
		.header-buttons {
			animation: header-buttons 0.8s forwards 0.7s;
			@keyframes header-buttons {
				from {
					max-height: 0;
					opacity: 0;
				}
				to {
					max-height: 100px;
					opacity: 1;
				}
			}
		}
	}
}

.header-loading {
	font-size: 1.5rem;
	transform: translateX(50%);
	position: absolute;
	top: 80%;
	right: 50%;
	color: map-get($map: $colors, $key: 'main');
}

.border-white {
	border: 6px solid white;
}

.values {
	min-height: 4rem;
	margin-top: -4rem;
}
.statics {
	background-image: linear-gradient(#0009, #0009), url('@/assets/2.jpg');
	background-attachment: fixed;
	background-size: cover;
	padding: 4rem 0 9rem;
	background-position: center;
}
.quality {
	background-image: linear-gradient(#0009, #0009), url('@/assets/2.jpg');
	background-attachment: fixed;
	background-size: cover;
	padding: 4rem 0 9rem;
	background-position: center;
}
.services {
	margin-top: -5rem;
	margin-bottom: 5rem;
}
.section-card {
	padding: 3rem 3.5rem;
	border-radius: 2rem;
	box-shadow: map-get($map: $shadows, $key: 3);
	background-color: white;
	position: relative;
	z-index: 2;
	@media (max-width: 992px) {
		padding: 2rem;
	}
}
.contacts {
	margin-bottom: -6rem;
	margin-top: -6rem;
	&-title {
		margin-bottom: 4rem;
		@media (max-width: 768px) {
			margin-bottom: 1rem;
		}
	}
	a:hover {
		text-decoration: underline;
	}
}
</style>
<style>
.quality-content * {
	display: none;
}
.quality-content *:nth-child(1),
.quality-content *:nth-child(2) {
	display: block;
}
.why-content ul {
	list-style: disc;
	list-style-position: inside;
}
.why-content p {
	margin-bottom: 0.3rem;
}
</style>

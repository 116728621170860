import HomeView from "../views/HomeView.vue"
import AllServices from "../views/Services/AllServices.vue"
import AboutUs from "../views/AboutUs.vue"
import ContactUs from "../views/ContactUs.vue"

export default [
    {
        path: '/',
        name: 'Home',
        component: HomeView
    },
    {
        path: '/all-services',
        name: 'AllServices',
        component: AllServices
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        component: AboutUs
    },
    {
        path: '/contact-us',
        name: 'ContactUs',
        component: ContactUs
    },

]

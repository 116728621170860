import Vue from 'vue';
import Vuex from 'vuex';
import feedback from './modules/feedback';
import services from './modules/services';
import aboutus from './modules/aboutus';
import values from './modules/values';
import contacts from './modules/contacts';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    services,
    feedback,
    aboutus,
    values,
    contacts,
  },
});

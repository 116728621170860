import * as types from "../mutations"

export default {
    namespaced: true,

    state: {
        contacts: [],
        branch: {}
    },

    actions: {
        //fetch all contacts
        fetchAll({ commit }) {
            return axios.get('/branches').then(res => {
                commit(types.CONTACTS_STORE, res.data)
                return res.data
            })
        },
    },

    mutations: {
        //fill the state with contacts
        [types.CONTACTS_STORE](state, branch) {
            state.contacts = branch && branch[0].contacts
            state.branch = branch && branch[0]
        }
    },
}
<template>
	<footer
		class="sec-bg"
		:class="{ home: $route.name === 'Home' }">
		<div class="container">
			<div class="top-section">
				<div class="page-footer">
					<!-- logo and description -->
					<div class="page-footer__part page-footer__part--main">
						<div class="d-flex">
							<div class="description">
								<div class="image mb-4 mx-auto mx-sm-0">
									<router-link :to="{ name: 'Home' }">
										<img
											src="@/assets/logo.png"
											alt="brand image" />
									</router-link>
								</div>
								<div class="mb-4 text-justify">
									{{ $t('footer') }}
								</div>
								<div
									class="social social--fill main-text justify-content-center justify-content-sm-start">
									<a
										v-if="contacts.twitter"
										:href="contacts.twitter"
										target="_blank"
										title="twitter"
										class="twitter"></a>
									<a
										v-if="contacts.instagram"
										:href="contacts.instagram"
										target="_blank"
										title="instagram"
										class="instagram"></a>
									<a
										v-if="contacts.linkedIn"
										:href="contacts.linkedIn"
										target="_blank"
										title="linkedIn"
										class="linkedIn"></a>
									<a
										v-if="contacts.facebook"
										:href="contacts.facebook"
										target="_blank"
										title="facebook"
										class="facebook"></a>
								</div>
							</div>
						</div>
					</div>
					<div class="page-footer__part page-footer__part--spacer-1"></div>

					<!-- website links -->
					<div class="page-footer__part page-footer__part--page-links">
						<div class="links links--page-links">
							<div class="links-title">
								{{ $t('links.quick-links') }}
							</div>
							<ul class="links-list">
								<li class="links-list__item">
									<router-link :to="{}">
										{{ $t('links.home') }}
									</router-link>
								</li>
								<li class="links-list__item">
									<router-link :to="{ name: 'AllServices' }">
										{{ $t('links.services') }}
									</router-link>
								</li>
								<li class="links-list__item">
									<router-link :to="{ name: 'AboutUs' }">
										{{ $t('links.about') }}
									</router-link>
								</li>
								<li class="links-list__item">
									<router-link :to="{ name: 'ContactUs' }">
										{{ $t('links.contact') }}
									</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="page-footer__part page-footer__part--spacer-2"></div>

					<!-- categories links -->
					<div class="page-footer__part page-footer__part--categories">
						<div class="links links--categories">
							<div class="links-title">
								{{ $t('links.services') }}
							</div>
							<ul class="links-list">
								<li
									class="links-list__item"
									v-for="service in services"
									:key="service.id">
									{{ service.title }}
								</li>
							</ul>
						</div>
					</div>
					<div class="page-footer__part page-footer__part--spacer-3"></div>

					<!-- contact us -->
					<div class="page-footer__part page-footer__part--contact-us">
						<div class="links links--contact-us">
							<ul
								class="links-list"
								v-if="contacts">
								<li class="links-list__item">
									<div class="links-title p-0 mb-1">
										{{ $t('links.information') }}
									</div>
								</li>
								<li
									class="links-list__item"
									dir="ltr">
									<a
										:href="'tel://' + contacts.mobile"
										target="_blank">
										{{ contacts.mobile }}
									</a>
								</li>
								<li class="links-list__item">
									<a
										:href="'mailto://' + contacts.email"
										target="_blank">
										{{ contacts.email }}
									</a>
								</li>
								<li class="links-list__item">
									<div class="links-title p-0 mb-1 mt-2">
										{{ $t('links.address') }}
									</div>
								</li>
								<li class="links-list__item">
									{{ contacts.address }}
								</li>
								<li class="links-list__item">
									{{ branch.openHours }}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="bottom-section pt-4 pb-5">
				<div class="copy-rights">{{ $t('copyrights') }}&copy;{{ new Date().getFullYear() }}</div>
				<!-- &nbsp;
				<a
					href="https://beetronix.com"
					target="_blank"
					class="beetronix"
					role="link"
					tabindex="0"
					title="beetronix"></a> -->
			</div>
		</div>
	</footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
	name: 'MainFooter',

	computed: {
		...mapState({
			contacts: (state) => state.contacts.contacts,
			branch: (state) => state.contacts.branch,
			services: (state) => state.services.services,
		}),
		...mapGetters({
			about: 'aboutus/getValue',
		}),
	},
};
</script>
<style lang="scss" scoped>
@use 'sass:list';
@use 'sass:map';
@import '@/style/style.scss';

* {
	color: white;
	font-size: 0.8rem;
}
.description {
	.image {
		height: 2.5rem;
		width: max-content;
	}
}
.top-section {
	border-bottom: 1px solid map-get($map: $colors, $key: 'main');
	padding: 2rem 0;
}
.home .top-section {
	padding-top: 9rem;
}
/********** */

$center-for-mobile: 576px;
$part-margin-x: 10px;
$bottom-section-justify-content: center;

%list-title {
	padding: 5px;
	font-weight: bold;
	margin-bottom: 0.7rem;
	text-transform: capitalize;
}
%list-item {
	padding: 5px;
	line-height: 1em;
	margin-bottom: 0.15rem;
}
%list-item-hover {
	color: map-get($map: $colors, $key: 'main');
}

$page-links-col: 1;
$categories-col: 1;
$contact-us-col: 1;

$parts: (
	'main': (
		'order': 1,
		'width': 25%,
		'widths': (
			992px: 25%,
			768px: 50%,
			576px: 100%,
		),
	),
	'page-links': (
		'order': 3,
		'width': 20%,
		'widths': (
			992px: 20%,
			768px: 45%,
			576px: 100%,
		),
	),
	'categories': (
		'order': 5,
		'width': 20%,
		'widths': (
			992px: 20%,
			768px: 45%,
			576px: 100%,
		),
	),
	'contact-us': (
		'order': 7,
		'width': 25%,
		'widths': (
			992px: 20%,
			768px: 45%,
			576px: 100%,
		),
	),
	'spacer-1': (
		'order': 2,
		'width': 4%,
		'widths': (
			992px: 4%,
			768px: 5%,
			576px: 0%,
		),
	),
	'spacer-2': (
		'order': 3,
		'width': 3%,
		'widths': (
			992px: 4%,
			768px: 0%,
			576px: 0%,
		),
	),
	'spacer-3': (
		'order': 5,
		'width': 3%,
		'widths': (
			992px: 4%,
			768px: 10%,
			576px: 0%,
		),
	),
);

@each $part-name, $part-prop in $parts {
	.page-footer__part--#{$part-name} {
		order: map.get($part-prop, 'order');
		width: map.get($part-prop, 'width');
	}
	@each $breakPx, $width in map.get($part-prop, 'widths') {
		@media (max-width: $breakPx) {
			.page-footer__part--#{$part-name} {
				width: $width;
			}
		}
	}
}

/****************/
.page-footer {
	display: flex;
	flex-wrap: wrap;
	@if $center-for-mobile {
		@media (max-width: $center-for-mobile) {
			justify-content: center;
			text-align: center;
		}
	}
	&__part {
		margin: $part-margin-x 0;
		padding: 0 5px;
		&--spacer-1,
		&--spacer-2,
		&--spacer-3 {
			padding: 0;
		}
	}
}
.links {
	&-title {
		@extend %list-title;
	}
	&-list {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		&__item {
			@extend %list-item;
			& > a:hover {
				@extend %list-item-hover;
				cursor: pointer;
			}
		}
	}
}
.links--page-links {
	.links-list__item {
		width: calc(100% / $page-links-col);
	}
}
.links--categories {
	.links-list__item {
		width: calc(100% / $categories-col);
	}
}
.links--contact-us {
	.links-list__item {
		width: calc(100% / $contact-us-col);
	}
}
.bottom-section {
	display: flex;
	align-items: center;
	justify-content: $bottom-section-justify-content;
	* {
		font-size: 0.85em;
	}
}
</style>

import * as types from "../mutations"

export default {
    namespaced: true,

    status: {
        values: []
    },

    actions: {
        //fetch all values
        fetchAll({ commit }) {
            return axios.get('/company-values').then(res => {
                commit(types.VALUES_STORE, res.data)
                return res.data
            })
        },

        //return the values. and if it's empty fetch values
        fetchAllOrGet({ commit, state }) {
            return state.values || axios.get('/company-values').then(res => {
                commit(types.VALUES_STORE, res.data)
                return res.data
            })
        },

        //fetch one values by it's slug
        fetchBySlug({ commit }, { slug }) {
            return axios.get(`values/${slug}`).then(res => res.data)
        },

        //get one values by it's slug, and if it's not found fetch it
        fetchByStateOrSlug({ commit, state }, { slug }) {
            let values = null;
            if (state.values) {
                values = state.values.find(e => e.slug === slug)
            }
            return values || axios.get(`/company-values/${slug}`).then(res => res.data)
        }
    },

    mutations: {
        //fill the state with values
        [types.VALUES_STORE](state, values) {
            state.values = values
        }
    },

    getters: {
        //return one values by it's slug
        getBySlug: state => slug => state.values.find(e => e.slug === slug)
    }
}
import * as types from "../mutations"

export default {
    namespaced: true,

    state: {
        about: []
    },

    actions: {
        //fetch all about
        fetchAll({ commit }) {
            return axios.get('/aboutus').then(res => {
                commit(types.ABOUT_STORE, res.data)
                return res.data
            })
        },
    },

    mutations: {
        //fill the state with about
        [types.ABOUT_STORE](state, about) {
            state.about = about
        }
    },

    getters: {
        mission: state => state.about?.find(e => e.key === 'mission').value,
        about: state => state.about?.find(e => e.key === 'about').value,
        vision: state => state.about?.find(e => e.key === 'vision').value,

        getValue: state => value => state.about?.find(e => e.key === value)?.value
    }
}
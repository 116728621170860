<template>
	<div class="page py-4">
		<section
			class="services"
			v-if="!loading">
			<div class="container">
				<div
					class="section-card text-center"
					data-aos="zoom-in">
					<h3 class="sub-title">{{ $t('services.title-1') }}</h3>
					<h2 class="title mb-5">{{ $t('services.title-2') }}</h2>

					<div class="row gy-4">
						<div
							data-aos="zoom-in"
							class="col-6 col-md-4"
							v-for="(service, i) in services"
							:key="i">
							<service-card
								:data="service"
								:class="{
									'radius-tr': !(i % 3),
									'radius-bl': !((i % 3) - 2),
								}"></service-card>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import ServiceCard from '@/components/ServiceCard.vue';
import { mapState } from 'vuex';

export default {
	name: 'AllServices',

	props: ['loading'],

	components: { ServiceCard },

	computed: {
		...mapState({
			services: (state) => state.services.services,
		}),
	},

	metaInfo: {
		title: 'Services',
		titleTemplate: '%s | Esnad united',
	},
};
</script>

<style lang="scss" scoped></style>

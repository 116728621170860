<template>
	<div>
		<form
			ref="form"
			@submit.prevent="submit">
			<div class="row gx-2">
				<div
					class="col-12"
					:class="{ 'col-sm-6': dialog }">
					<label for="name">{{
						$t('contact.form.name-label')
					}}</label>
					<div class="input">
						<input
							v-model="name"
							:placeholder="$t('contact.form.name')"
							type="text"
							id="name"
							required />
					</div>
				</div>
				<div
					class="col-12"
					:class="{ 'col-sm-6': dialog }">
					<label for="email">{{
						$t('contact.form.email-label')
					}}</label>
					<div class="input">
						<input
							v-model="email"
							type="email"
							:placeholder="$t('contact.form.email')"
							id="email"
							required />
					</div>
				</div>
				<div
					class="col-6"
					:class="{ 'col-sm-6': dialog }">
					<label for="phone">{{
						$t('contact.form.phone-label')
					}}</label>
					<div class="input">
						<input
							v-model="phone"
							type="text"
							:placeholder="$t('contact.form.phone')"
							id="phone"
							required />
					</div>
				</div>
				<div
					class="col-6"
					:class="{ 'col-sm-6': dialog }">
					<label for="type">{{
						$t('contact.form.type-label')
					}}</label>

					<div class="input">
						<!-- massage type select -->
						<div
							ref="DropdownToggler"
							class="select"
							@click="openDropdown = !openDropdown"
							@keydown.enter="openDropdown = !openDropdown"
							role="button"
							tabindex="0">
							<!-- massage type placeholder -->
							<span
								:class="{
									'muted-select-label': !type,
									'select-label--opend': openDropdown,
								}"
								class="select-label">
								<i class="fas fa-caret-down"></i>
								{{ type || $t('contact.form.type') }}
							</span>

							<!-- massage type dropdown -->
							<div
								class="type-dropdown"
								v-if="openDropdown">
								<ul>
									<!-- massage type dropdown item -->
									<li
										v-for="(typeIndex, i) in massageTypes"
										@click="type = typeIndex"
										@keydown.enter="type = typeIndex"
										:key="`massage-type-${i}`"
										tabindex="0">
										{{ typeIndex }}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12">
					<label for="message">{{
						$t('contact.form.message-label')
					}}</label>
					<div class="input">
						<textarea
							v-model="body"
							:placeholder="$t('contact.form.message')"
							id="message"
							:rows="4"
							required>
						</textarea>
					</div>
				</div>
			</div>
			<button
				:disabled="sent || loading"
				class="button"
				type="submit">
				{{
					loading
						? $t('contact.form.loading')
						: sent
						? $t('contact.form.sent')
						: $t('contact.form.send')
				}}
			</button>
		</form>
	</div>
</template>

<script>
export default {
	name: 'ContactUsForm',

	props: ['dialog', 'messageType'],

	data() {
		return {
			openDropdown: false,

			name: null,
			email: null,
			phone: null,
			type: null,
			body: null,

			massageTypes: [
				this.$t('contact.form.types.consultation'),
				this.$t('contact.form.types.appointment'),
				this.$t('contact.form.types.Suggestion'),
				this.$t('contact.form.types.Enquiry'),
				this.$t('contact.form.types.Complaint'),
			],

			loading: false,
			sent: false,
		};
	},

	methods: {
		submit() {
			this.loading = true;
			this.error = null;
			this.$store
				.dispatch('feedback/feedback', {
					name: this.name,
					email: this.email,
					phone: this.phone,
					type: this.type,
					body: this.body,
				})
				.then(() => {
					this.sent = true;
				})
				.catch((err) => {
					this.error = err;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

	mounted() {
		this.type = this.messageType;
		//close dropdown in window
		window.addEventListener('click', (e) => {
			if (this.$refs.DropdownToggler?.contains(e.target)) return;
			this.openDropdown = false;
		});
	},
};
</script>

<style lang="scss" scoped>
label {
	margin-bottom: 0.5em;
	text-transform: capitalize;
	font-size: 0.8rem;
}
.input {
	border: 1px solid #aaa;
	border-radius: 10px;
	padding: 5px 10px;
	margin-bottom: 1em;
}
input,
textarea {
	width: 100%;
	font-size: 0.8rem;
	resize: none;
	&:focus {
		border-bottom: 1px solid #aaa;
	}
}
.muted-select-label,
*::placeholder {
	color: #999;
	opacity: 1;
}

.select {
	position: relative;
	* {
		font-size: 0.8rem;
	}
	.select-label {
		&:focus {
			border-bottom: 1px solid #aaa;
		}
	}
	.type-dropdown {
		position: absolute;
		right: 0;
		left: 0;
		top: 110%;
		background: white;
		box-shadow: 0 0 20px #0003;
		border-radius: 10px;
		padding: 10px;
		animation: typeDropdownInter 0.5s forwards;
		@keyframes typeDropdownInter {
			from {
				transform: translateY(-20px);
				opacity: 0;
			}
			to {
				transform: translateY(0);
				opacity: 1;
			}
		}

		> ul {
			list-style: none;

			> li {
				padding: 3px;
			}
		}
	}
}
</style>

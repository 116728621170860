import Vue from "vue";
import VueI18n from "vue-i18n";
import { LOCAL_STORAGE_NAME } from "../constant";

Vue.use(VueI18n)

function loadLocaleMessages() {
    const locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}

const i18n = new VueI18n({
    locale: 'ar',
    fallbackLocale: 'Ar',
    messages: loadLocaleMessages(),

})
export default i18n;

const html = document.querySelector('html')

export function changeI18nLanguage(l) {
    if (l === 'en') {
        i18n.locale = 'en';
        html.lang = 'en'
        html.dir = 'ltr'

        localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify({ language: 'en' }))
    }
    if (l === 'ar') {
        i18n.locale = 'ar';
        html.lang = 'ar'
        html.dir = 'rtl'

        localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify({ language: 'ar' }))
    }
}

if (LOCAL_STORAGE_NAME in localStorage) {
    i18n.locale = JSON.parse(localStorage[LOCAL_STORAGE_NAME]).language;
    html.lang = i18n.locale
    html.dir = i18n.locale === 'en' ? 'ltr' : 'rtl'
} else {
    localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify({ language: i18n.locale }))
}
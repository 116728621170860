<template>
	<div
		class="services-card radius-lg"
		tabindex="0"
		role="button"
		@keydown.enter="$eventBus.$emit('show-dialog', { slug: data.slug })"
		@click="$eventBus.$emit('show-dialog', { slug: data.slug })">
		<div class="image">
			<img
				:src="data.primaryImage?.url"
				:alt="' image'" />
		</div>
		<div class="name">{{ data.title }}</div>
		<p class="description">
			{{ data.summary }}
		</p>
		<div class="more">
			{{ $t('read-more') }}
			<i
				class="fas"
				:class="$i18n.locale === 'en' ? 'fa-long-arrow-right' : 'fa-long-arrow-left'"></i>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ServiceCard',

	props: ['data'],
};
</script>

<style lang="scss" scoped>
@import '@/style/style.scss';
.services-card {
	display: block;
	text-align: center;
	padding: 1rem;
	border: 1px solid map-get($map: $colors, $key: 'main');
	height: 100%;
	.image {
		width: 3rem;
		height: 3rem;
		margin: auto;
		margin-bottom: 0.5rem;
	}
	.name {
		font-size: 1rem;
		font-weight: bold;
		margin-bottom: 0.5rem;
	}
	.description {
		font-size: 0.9rem;
		font-weight: lighter;
		margin-bottom: 0.5rem;
		line-height: 1.7em;
		height: 5.1em;
		overflow: hidden;
	}
	.more {
		font-size: 0.8rem;
		font-weight: lighter;
		text-transform: uppercase;
		[dir='ltr'] & {
			letter-spacing: 4px;
		}
		transition: 1s;
		width: max-content;
		margin: auto;
		svg {
			font-size: 0.7rem;
		}
	}
	[dir='ltr'] & {
		&:hover {
			.more {
				transition: 0.3s;
				letter-spacing: 6px;
			}
		}
	}
	[dir='rtl'] & {
		&:hover {
			.more {
				transition: 0.3s;
				transform: scale(1.1);
			}
		}
	}
}
</style>
